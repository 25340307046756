<template>
  <div>
    <div class="mt-1 d-flex justify-content-end">
      <label
        v-if="!uploadPending"
        for="document-upload"
        class="text-primary cursor-pointer mb-2 d-block"
      >
        <i class="far fa-cloud-upload text" /> Dokument hinzufügen
      </label>
      <input
        id="document-upload"
        ref="fileInput"
        type="file"
        multiple
        accept=".pdf"
        class="d-none"
        :disabled="uploadPending"
        @change="upload"
      >
    </div>
    <div class="table-responsive">
      <table class="table table-hover w-100">
        <thead>
          <tr>
            <th
              class="border-0"
              scope="col"
            >
              Name
            </th>
            <th
              class="border-0"
              scope="col"
            >
              Erstellt am
            </th>
            <th
              v-if="me.role === 10"
              class="border-0"
              scope="col"
            >
              Ersteller
            </th>
            <th
              class="border-0"
              scope="col"
            />
          </tr>
        </thead>
        <tbody>
          <!-- Relevant Documents -->
          <template v-for="(document, index) in relevantDocuments">
            <DocumentsTr
              :key="index+'relevantDocListItem'"
              :document="document"
              class="active-doc"
              :file-name="document.displayName"
              :creator="formatCreator(document.file.user)"
            />
          </template>
          <tr
            v-if="archivedDocuments.length > 0 && me.role === 10"
            class="font-weight-bold cursor-pointer"
            @click="showArchivedDocuments = !showArchivedDocuments"
          >
            <td class="pt-3">
              Ältere Vertragsdokumente
            </td>
            <td />
            <td v-if="me.role === 10" />
            <td class="pt-3">
              <div class="text-right">
                <i
                  class="fas"
                  :class="{'fa-angle-left': !showArchivedDocuments, 'fa-angle-down': showArchivedDocuments}"
                />
              </div>
            </td>
          </tr>
          <!-- Archiv -->

          <template v-for="(document, index) in archivedDocuments">
            <transition
              :key="index+'archivedDocListItem'"
              name="slide-fade-right"
            >
              <DocumentsTr
                v-if="showArchivedDocuments"
                :document="document"
                :creator="formatCreator(document.file.user)"
                :file-name="document.displayName"
              />
            </transition>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import DocumentsTr from '@/components/DocumentsTr.vue'
import feathers from '../api'
import axios from 'axios'
import formatDate from '@/filters/formatDate.js'

export default {
  components: {
    DocumentsTr
  },
  props: {
    order: {
      required: true,
      type: Object
    },
    active: {
      default: false,
      type: Boolean
    }
  },
  data () {
    return {
      loading: true,
      documents: [],
      uploadPending: false,
      formatDate,
      showArchivedDocuments: false
    }
  },
  computed: {
    me () {
      return this.$store.getters['auth/user'].user
    },
    pdfDocuments () {
      if (this.order.status !== 0) {
        return this.documents.filter(d => d.type === 'PDF').map(document => ({ ...document, displayName: 'Police' }))
      } else {
        return []
      }
    },
    xmlDocuments () {
      if (this.me.role === 10) {
        return this.documents.filter(d => d.type === 'XML').map(document => ({ ...document, displayName: 'XML Vertrag' }))
      } else {
        return []
      }
    },
    xmlCustomerDocuments () {
      if (this.me.role === 10) {
        return this.documents.filter(d => d.type === 'XML-CUSTOMER').map(document => ({ ...document, displayName: 'XML Kunden' }))
      } else {
        return []
      }
    },
    commentAndExtraDocuments () {
      if (this.me.role === 10) {
        return this.documents.filter(d => d.type === 'COMMENT' || d.type === 'EXTRA')
      } else {
        return []
      }
    },
    relevantDocuments () {
      const documents = []
      if (this.order.product.rate && this.order.product.rate.agb && this.order.product.rate.agb.file) {
        const garantiebedingung = this.order.product.rate.agb
        garantiebedingung.displayName = 'Garantiebedingungen'
        documents.push(garantiebedingung)
      }
      documents.push(
        ...this.pdfDocuments.slice(0, 1),
        ...this.xmlDocuments.slice(0, 1),
        ...this.xmlCustomerDocuments.slice(0, 1),
        ...this.commentAndExtraDocuments
      )
      return documents.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    },
    archivedDocuments () {
      return [
        ...this.pdfDocuments.slice(1),
        ...this.xmlDocuments.slice(1),
        ...this.xmlCustomerDocuments.slice(1)
      ].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler: 'fetchData'
    },
    active: {
      handler: 'fetchData'
    }
  },
  methods: {
    formatCreator (user) {
      if (!user || !user.lastname) return ''
      if (!user.firstname) return user.lastname
      return `${user.firstname} ${user.lastname}`
    },
    async fetchData () {
      this.loading = true
      try {
        this.documents = await feathers.service('order-document').find({
          query: {
            orderId: this.order._id,
            $client: {
              paginate: false
            },
            $sort: {
              createdAt: -1
            }
          }
        })
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    async upload (event) {
      if (this.uploadPending || !event.target.files || !event.target.files[0]) return
      try {
        this.uploadPending = true
        const toUploadFiles = []
        for (const key in event.target.files) {
          if (typeof event.target.files[key] === 'object') {
            toUploadFiles.push(event.target.files[key])
          }
        }
        const documents = await Promise.all(toUploadFiles.map(async (toUploadFile) => {
          const file = await this.uploadFile(toUploadFile)
          const document = await feathers.service('order-document').create({
            orderId: this.order._id,
            type: 'EXTRA',
            fileId: file._id
          })
          return document
        }))

        this.documents.push(...documents)
      } catch (error) {
        console.error(error)
      } finally {
        // reset fileInput
        this.$refs.fileInput.value = ''
        if (this.$refs.fileInput.value) {
          this.$refs.fileInput.type = 'text'
          this.$refs.fileInput.type = 'file'
        }
        this.uploadPending = false
      }
    },
    async uploadFile (toUploadFile) {
      const file = await feathers.service('file').create({
        name: toUploadFile.name,
        type: toUploadFile.type
      })
      await axios.put(file.uploadUrl, toUploadFile, this.config)
      return file
    }
  }
}
</script>
<style scoped lang="scss">
@import "@/assets/scss/_variables.scss";
.icon-wrapper{
  width: 80px;
  height: 80px;
}
.load-more {
  color: inherit !important;

  a:hover {
    text-decoration: underline !important;
  }
}
.active-doc{
  border-left: 5px solid $primary;
}
</style>
