<template>
  <div>
    <ca-header
      :heading="warrentyNumber"
      :loading="loading"
    >
      <template #buttonSpace>
        <router-link
          v-if="canBeExtended"
          class="btn btn-success text-white mr-2"
          tag="button"
          :to="{name: 'OrderExtend', params: {orderId: order._id}}"
          data-test="order-detail-extend"
        >
          <i class="far fa-plus" /> Verlängern
        </router-link>
        <router-link
          v-if="me.role === 10"
          class="btn btn-secondary text-primary mr-2"
          tag="button"
          :to="{name: 'OrderEdit', params: {orderId: order._id}}"
        >
          <i class="far fa-pen" /> Bearbeiten
        </router-link>
        <b-dropdown
          v-if="me.role === 10"
          variant="primary"
          right
          :disabled="emailResendPending"
        >
          <template slot="button-content">
            <i class="fal fa-envelope" /> E-Mail erneut senden
          </template>
          <b-dropdown-item>
            <button
              class="btn btn-link p-0"
              @click="resendEmail('toHaendler')"
            >
              <i class="fal fa-envelope" /> E-Mail an Händler erneut senden
            </button>
          </b-dropdown-item>
          <b-dropdown-item>
            <button
              class="btn btn-link p-0"
              @click="resendEmail('toGarantiegeber')"
            >
              <i class="fal fa-envelope" /> E-Mail an Mobile erneut senden
            </button>
          </b-dropdown-item>
        </b-dropdown>
      </template>
      <template #alert>
        <template v-if="emailResendSuccess">
          <ca-alert
            v-for="(mail, index) of emailResendSuccess"
            :key="'resendMail' + index"
            variant="success"
          >
            E-Mail erfolgreich neu versendet an "{{ mail.envelope.to.join(', ') }}" mit dem Betreff "{{ mail.envelope.subject }}"
          </ca-alert>
        </template>
        <ca-alert
          v-if="!!emailResendError"
          variant="danger"
        >
          E-Mail konnte nicht neu versendet werden
        </ca-alert>
      </template>
    </ca-header>
    <div class="container-fluid px-4 pt-4">
      <div
        v-if="!loading"
        class="row"
      >
        <div class="col-12 col-xl-8 col-xxl-6">
          <!-- <GvcForm
            v-if="order.gvcs && order.gvcs.length > 0"
            :order="order"
          /> -->
          <ca-card class="mb-3">
            <template #header>
              <div class="form-row">
                <div class="col">
                  <h4 class="mb-0">
                    <span v-if="order.status === 0">Anfrage</span>
                    <span v-else-if="order.status === 1">Vertrag</span>
                    <span v-else>Vertrag <span
                      class="badge badge-warning text-white"
                    >{{ formatOrderStatus(order.status) }}</span></span>
                  </h4>
                </div>
              </div>
            </template>
            <template #body>
              <h5>Garantiegeber</h5>
              <div class="row mb-3">
                <div class="col-12 col-md-4">
                  Firma
                </div>
                <div class="col-12 col-md-8">
                  {{ order.user.company }}
                  <router-link
                    v-if="me.role === 10"
                    target="_blank"
                    :to="{name: 'UserEdit', params: {userId: order.user._id }}"
                  >
                    <i class="far fa-external-link" />
                  </router-link>
                </div>
                <div class="col-12 col-md-4">
                  Straße
                </div>
                <div class="col-12 col-md-8">
                  {{ order.user.street }}
                </div>
                <div class="col-12 col-md-4">
                  PLZ, Ort
                </div>
                <div class="col-12 col-md-8">
                  {{ order.user.zip }} {{ order.user.town }}
                </div>
                <div class="col-12 col-md-4">
                  Land
                </div>
                <div class="col-12 col-md-8">
                  {{ order.user.country }}
                </div>
                <div class="col-12 col-md-4">
                  Telefon
                </div>
                <div class="col-12 col-md-8">
                  {{ order.user.telephone }}
                </div>
                <div class="col-12 col-md-4">
                  Mobil
                </div>
                <div class="col-12 col-md-8">
                  {{ order.user.mobile }}
                </div>
                <div class="col-12 col-md-4">
                  Email
                </div>
                <div class="col-12 col-md-8">
                  {{ order.user.email }}
                </div>
              </div>
              <h5>Fahrzeugdaten</h5>
              <div class="row mb-3">
                <div class="col-12 col-md-4">
                  Kennzeichen
                </div>
                <div class="col-12 col-md-8">
                  {{ order.car.licensePlate }}
                </div>
                <div class="col-12 col-md-4">
                  Marke
                </div>
                <div class="col-12 col-md-8">
                  {{ order.car.producer }}
                </div>
                <div class="col-12 col-md-4">
                  KW
                </div>
                <div class="col-12 col-md-8">
                  {{ order.car.kw }}
                </div>
                <div class="col-12 col-md-4">
                  Fahrgestell-Nr.
                </div>
                <ChassiBlacklistInfo
                  ref="chassiBlacklistInfo"
                  :chassi-number="order.car.chassi"
                />
                <div class="col-12 col-md-4">
                  Erstzulassung
                </div>
                <div class="col-12 col-md-8">
                  {{ order.car.registrationDate | formatDate }}
                </div>
                <div class="col-12 col-md-4">
                  Model
                </div>
                <div class="col-12 col-md-8">
                  <span v-if="order.car.type && !order.car.noHsnTsn">{{ order.car.type }}<br></span>{{ order.car.typePolice }}
                </div>
                <div class="col-12 col-md-4">
                  Km bei Abschluss
                </div>
                <div class="col-12 col-md-8">
                  {{ order.car.km | formatNumber }}
                </div>
                <div class="col-12 col-md-4">
                  Neu-/Gebrauchtwagen
                </div>
                <div class="col-12 col-md-8">
                  {{ newOrUsed }}
                </div>
                <div class="col-12 col-md-4">
                  Kaufpreis
                </div>
                <div class="col-12 col-md-8">
                  {{ order.car.price | formatPrice }}
                </div>
              </div>
              <h5>Halter</h5>
              <div class="row mb-3">
                <div class="col-12 col-md-4">
                  Name
                </div>
                <div class="col-12 col-md-8">
                  {{ order.owner.firstname }} {{ order.owner.lastname }}
                </div>
                <div class="col-12 col-md-4">
                  Straße
                </div>
                <div class="col-12 col-md-8">
                  {{ order.owner.street }}
                </div>
                <div class="col-12 col-md-4">
                  PLZ, Ort
                </div>
                <div class="col-12 col-md-8">
                  {{ order.owner.city.zip }} {{ order.owner.city.name }}
                </div>
                <div class="col-12 col-md-4">
                  Telefon
                </div>
                <div class="col-12 col-md-8">
                  {{ order.owner.phone }}
                </div>
                <div class="col-12 col-md-4">
                  Mobil
                </div>
                <div class="col-12 col-md-8">
                  {{ order.owner.mobile }}
                </div>
                <div class="col-12 col-md-4">
                  Email
                </div>
                <div class="col-12 col-md-8">
                  {{ order.owner.email }}
                </div>
              </div>
              <h5>Vertragsdaten</h5>
              <div class="row mb-3">
                <div
                  v-if="me.role === 10 && (order.isKaufschutz || (order.product.rate && order.product.rate.name))"
                  class="col-12 col-md-4"
                >
                  Vertragstyp
                </div>
                <div
                  v-if="me.role === 10 && (order.isKaufschutz || (order.product.rate && order.product.rate.name))"
                  class="col-12 col-md-8"
                >
                  {{ orderType }}
                </div>
                <div class="col-12 col-md-4">
                  Beginn
                </div>
                <div class="col-12 col-md-8">
                  {{ order.product.beginContractDate | formatDate }}
                </div>
                <template v-if="order.productType !== 'SORGLOS_FLAT'">
                  <div class="col-12 col-md-4">
                    Laufzeit
                  </div>
                  <div class="col-12 col-md-8">
                    <span v-if="order.product.rate">{{ order.product.rate.laufzeit }} Monate</span>
                  </div>
                </template>
                <div class="col-12 col-md-4">
                  Ende
                </div>
                <div class="col-12 col-md-8">
                  {{ order.product.endContractDate | formatDate }}
                </div>
                <div class="col-12 col-md-4">
                  Tarif
                </div>
                <div class="col-12 col-md-8">
                  <span v-if="order.product.rate">{{ order.product.rate.name }}</span>
                </div>
                <div class="col-12 col-md-4">
                  Brutto Gesamt
                </div>
                <div class="col-12 col-md-8">
                  {{ order.product.gross | formatPrice }}
                </div>
                <div class="col-12 col-md-4">
                  Zahlweise
                </div>
                <div class="col-12 col-md-8">
                  {{ order.product.paymentRate | formatPaymentRate }}
                </div>
                <div class="col-12 col-md-4">
                  Brutto Zahlweise
                </div>
                <div class="col-12 col-md-8">
                  {{ order.product.rateBrutto | formatPrice }}
                </div>
                <template v-if="order.ancestorId">
                  <div class="col-12 col-md-4">
                    Vorgänger-Garantie-Nummer
                  </div>
                  <div class="col-12 col-md-8">
                    <router-link :to="{name: 'OrderDetail', params: {orderId: order.ancestorId}}">
                      {{ order.contract.ancestorWarrantyNumber }}
                    </router-link>
                  </div>
                </template>
                <template v-if="order.mobilityOrder">
                  <div class="col-12 col-md-4">
                    Mobilitätstarif
                  </div>
                  <div class="col-12 col-md-8">
                    <router-link :to="{name: 'OrderDetail', params: {orderId: order.mobilityOrderId}}">
                      {{ order.mobilityOrder.contract.warrantyNumber }}
                    </router-link>
                  </div>
                </template>
              </div>
              <template v-if="order.faaren">
                <h5>Faaren</h5>
                <div class="row mb 3">
                  <div class="col-12 col-md-4">
                    Partner ID
                  </div>
                  <div class="col-12 col-md-8">
                    {{ order.faaren.partnerId }}
                  </div>
                  <div class="col-12 col-md-4">
                    Fahrzeug ID
                  </div>
                  <div class="col-12 col-md-8">
                    {{ order.faaren.carId }}
                  </div>
                </div>
              </template>
              <template v-if="order.attachmentFiles && order.attachmentFiles.length > 0">
                <h5>Anhang</h5>
                <div class="row mb-3">
                  <div
                    v-for="attachmentFile of order.attachmentFiles"
                    :key="attachmentFile._id"
                    class="col-12"
                  >
                    <SignedDownloadLink
                      :file="attachmentFile"
                    >
                      <template #default="{link}">
                        <a
                          :href="link"
                          :download="attachmentFile.name"
                        >
                          {{ attachmentFile.name }}
                        </a>
                      </template>
                    </SignedDownloadLink>
                  </div>
                </div>
              </template>
            </template>
          </ca-card>
          <!-- <ca-card class="mb-3">
            <template #header>
              <div class="row">
                <div class="col">
                  <h4 class="mb-0">
                    Schaden zu dieser Police melden
                  </h4>
                </div>
              </div>
            </template>
            <template #body>
              <router-link
                :to="{name: 'DamageReportCreateOrderId', params: {orderId: order._id}}"
                class="btn btn-primary mr-2"
              >
                Schaden melden
              </router-link>
              <router-link
                :to="{name: 'DamageReportListOrderId', params: {orderId: order._id}}"
                class="btn btn-primary mr-2"
              >
                Gemeldete Schäden anzeigen
              </router-link>
            </template>
          </ca-card> -->
        </div>
        <div class="col-12 col-xl-4 col-xxl-3">
          <RelatedOffers
            v-if="!loading && me.role === 10"
            :viewed-order="order"
            :orders="relatedOrders"
            :can-create-order="false"
            title="Andere Verträge mit gleicher Fahrgestellnummer"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-xl-8 col-xxl-6">
          <b-tabs class="tabs">
            <b-tab
              v-if="order && me.role === 10"
              title="Protokolle"
              active
            >
              <div class="bg-white border border-gray-2 border-top-0 rounded-bottom mb-3">
                <div class="card-body">
                  <protocols :entity-id="order._id" />
                </div>
              </div>
            </b-tab>
            <b-tab
              title="Dokumente"
              lazy
            >
              <div class="bg-white border border-gray-2 border-top-0 rounded-bottom mb-3">
                <div class="card-body">
                  <order-documents
                    v-if="order"
                    :order="order"
                  />
                </div>
              </div>
            </b-tab>
            <b-tab
              v-if="orderLogs && orderLogs.length !== 0 && me.role === 10"
              title="Logs"
              lazy
            >
              <order-logs
                is-detail
                :order-logs="orderLogs"
              />
            </b-tab>
            <dev-info :data="{order}" />
          </b-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CaCard from '@/components/Card'
import CaHeader from '@/components/Layout/Header'
import DevInfo from '@/components/DevInfo'
import SignedDownloadLink from '@/components/SignedDownloadLink'
import OrderLogs from '@/components/OrderLogs'
import Protocols from '@/components/Protocols'
// import Comments from '@/components/Comments'
import OrderDocuments from '@/components/OrderDocuments.vue'
// import GvcForm from '@/components/Forms/Order/GvcForm.vue'
import RelatedOffers from '@/components/RelatedOffers'
import ChassiBlacklistInfo from '@/components/ChassiBlacklistInfo'
import getWarrentyNumber from '@/helpers/getWarrentyNumber'
import formatDate from '@/filters/formatDate.js'
import feathers from '@/api'
import moment from 'moment'
import formatOrderStatus from '../../filters/formatOrderStatus.js'
import formatPrice from '../../filters/formatPrice.js'
import formatPaymentRate from '../../filters/formatPaymentRate.js'
import formatNumber from '../../filters/formatNumber.js'

export default {
  name: 'OrderDetail',
  components: {
    CaCard,
    CaHeader,
    DevInfo,
    OrderLogs,
    OrderDocuments,
    // GvcForm,
    RelatedOffers,
    SignedDownloadLink,
    ChassiBlacklistInfo,
    Protocols
    // Comments
  },
  filters: {
    formatDate,
    formatPaymentRate,
    formatPrice,
    formatNumber
  },
  data () {
    return {
      order: null,
      loading: false,
      emailResendPending: false,
      emailResendSuccess: false,
      emailResendError: null,
      typeFilter: undefined,
      orderLogs: null,
      relatedOrders: null,
      showBlacklistAddModal: false
    }
  },
  computed: {
    orderType () {
      if (this.order.isKaufschutz) return 'KAUFSCHUTZ'
      else if (this.order.product.rate && this.order.product.rate.rateType) return this.order.product.rate.rateType
      else return ''
    },
    newOrUsed () {
      return (this.order.car.monthFromRegistrationDate > 1 || this.order.car.km > 1000)
        ? 'Gebrauchtwagen' : 'Neuwagen'
    },
    warrentyNumber () {
      return getWarrentyNumber(this.order)
    },
    canBeExtended () {
      return this.me.role !== 0 &&
        !this.order.isExtended &&
        this.dateIsInReach(this.order.product.endContractDate, 4) &&
        this.order.product.rate &&
        this.order.product.rate.extendRateIds &&
        this.order.product.rate.extendRateIds.length > 0 &&
        this.order.product.rate.extendRates.some(rate => moment(this.order.product.endContractDate).add(rate.laufzeit, 'months').isSameOrBefore(moment(this.order.product.extendableUntil)))
    },
    me () {
      return this.$store.getters['auth/user'].user
    },
    baseQuery () {
      const baseQuery = {
        orderId: this.order._id,
        $sort: {
          createdAt: -1
        },
        $limit: 25
      }
      if (this.typeFilter !== undefined) {
        baseQuery.type = this.typeFilter
      }
      if (this.me.role !== 10) {
        baseQuery.$or = [
          {
            type: 'SMS'
          },
          {
            type: 'EMAIL',
            'data.envelope.subject': { $regex: 'GVC' }
          }
        ]
      }
      return baseQuery
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler: 'fetchData'
    }
  },
  methods: {
    formatOrderStatus,
    async fetchData () {
      try {
        this.loading = true
        this.order = await this.$store.dispatch('orders/get', this.$route.params.orderId)
        this.fetchOrderLogs()
        this.fetchRelatedOrders()
      } catch (error) {
        console.error(error)
        this.error = error
      } finally {
        this.loading = false
      }
    },
    async fetchOrderLogs () {
      this.orderLogs = (await this.$store.dispatch('order-log/find', {
        query: this.baseQuery
      })).data
    },
    async fetchRelatedOrders () {
      this.relatedOrders = (await this.$store.dispatch('orders/find', {
        query: { 'car.chassi': this.order.car.chassi, _id: { $ne: this.order._id } }
      })).data
    },
    hide (string, visibleChars = 4) {
      if (!string) return ''
      return string.substr(string.length - visibleChars).padStart(string.length, 'X')
    },
    dateIsInReach (date, weeks) {
      const diff = moment(date).startOf('day').diff(moment().startOf('day'), 'days')
      return diff <= 7 * weeks && diff >= 0
    },
    async resendEmail (reciever) {
      try {
        if (this.emailResendPending) return
        this.emailResendPending = true
        this.emailResendSuccess = null
        this.emailResendError = null
        const toHaendler = reciever === 'toHaendler'
        const toGarantiegeber = reciever === 'toGarantiegeber'
        const sentMails = await feathers.service('order-send-mails').create({
          orderId: this.order._id,
          toHaendler,
          toGarantiegeber,
          resend: true
        })
        this.emailResendSuccess = sentMails
      } catch (error) {
        this.emailResendError = error
        console.log(error)
      } finally {
        this.emailResendPending = false
      }
    },
    settypeFilter (statusCode) {
      this.typeFilter = statusCode
    }
  }
}
</script>
<style lang="scss" scoped>

.tabs ::v-deep{
  .tab-pane:focus {
    outline: none;
  }
}
</style>
