<template>
  <modal
    v-if="!loading"
    size="md"
    title="Fahrgestellnummer zur Blacklist hinzufügen"
    :closable="true"
    @close="closeModal(false)"
  >
    <template #body>
      <div class="row">
        <div class="col-12">
          Diese Fahrgestellnummer steht aktuell nicht auf der Blacklist. Sie können dieses Fahrzeug zur Blacklist hinzufügen:
        </div>
      </div>
      <basic-select
        v-model="blacklistEntry.reasonId"
        :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
        :horizontal="true"
        label="Grund"
        placeholder="Bitte auswählen..."
        :options="reasons"
        required
      />
      <basic-textarea
        v-if="reasonAllowsCustomText"
        v-model="blacklistEntry.reasonText"
        :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
        :horizontal="true"
        label="Eigener Grund"
        placeholder="Grund..."
        required
      />
    </template>
    <template
      #footer
    >
      <button
        class="btn btn-primary ml-2 float-right"
        :disabled="submitPending"
        @click="submit"
      >
        Sperren
      </button>
      <button
        class="btn hover-gray-2 text-primary float-right"
        @click="closeModal(false)"
      >
        Abbrechen
      </button>
    </template>
  </modal>
</template>

<script>
import Modal from '@/components/Modal'
import BasicSelect from '@/components/BaseComponents/BasicSelect'
import BasicTextarea from '@/components/BaseComponents/BasicTextarea'
export default {
  $_veeValidate: {
    validator: 'new' // give me my own validator scope.
  },
  components: {
    Modal,
    BasicSelect,
    BasicTextarea
  },
  props: {
    chassi: {
      type: String,
      required: true
    }
  },
  data: () => ({
    loading: false,
    submitPending: false,
    blacklistEntry: {},
    reasons: [],
    reasonIdsWithCustomText: []

  }),
  computed: {
    reasonAllowsCustomText: function () {
      return this.reasonIdsWithCustomText.includes(this.blacklistEntry.reasonId)
    }
  },
  watch: {
    chassi: {
      immediate: true,
      handler: 'fetchData'
    }
  },
  created () {
    this.findReasons()
  },
  methods: {
    closeModal (success) {
      this.$emit('add-close', success)
    },
    async fetchData () {
      this.loading = true
      try {
        const collection = (await this.$store.dispatch('blacklisted-chassis/find', { query: { chassi: this.chassi } })).data
        if (collection.length > 0) {
          this.blacklistEntry = collection[0]
          this.blacklistEntry.reasonText = ''
        } else {
          this.blacklistEntry = new this.$FeathersVuex.api.BlacklistedChassis()
          this.blacklistEntry.chassi = this.chassi
          this.blacklistEntry.authorId = this.$store.getters['auth/user'].user._id
        }
        this.blacklistEntry.active = true
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    async submit () {
      try {
        const valid = await this.$validator.validateAll()
        if (!valid) {
          return
        }
        this.submitPending = true
        await this.blacklistEntry.save()
        this.submitPending = false
        this.closeModal(true)
      } catch (error) {
        console.error(error)
      }
    },
    async findReasons () {
      const reasons = (await this.$store.dispatch('blacklisted-chassis-reasons/find')).data

      this.reasons = reasons.map(reason => {
        return { label: reason.label, value: reason._id }
      })

      this.reasonIdsWithCustomText = reasons.filter(reason => reason.allowCustomText).map(reason => {
        return reason._id
      })
    }
  }
}
</script>

<style>

</style>
