<template>
  <tr>
    <td>{{ fileName ? fileName : document.file.name }}</td>
    <td>{{ formatDate(document.createdAt, 'DD.MM.YYYY HH:mm') }}</td>
    <td v-if="me.role === 10">
      {{ creator }}
    </td>
    <td>
      <SignedDownloadLink
        :file="document.file"
      >
        <template #default="{link}">
          <a
            :href="link"
            :download="document.file.name"
          >
            <i class="fad fa-arrow-circle-down fa-sm" /> Herunterladen
          </a>
        </template>
      </SignedDownloadLink>
    </td>
  </tr>
</template>

<script>
import formatDate from '@/filters/formatDate.js'
import SignedDownloadLink from '@/components/SignedDownloadLink'
export default {
  components: {
    SignedDownloadLink
  },
  props: {
    document: {
      type: Object,
      default: () => {}
    },
    showFirst: {
      type: Boolean,
      default: true
    },
    fileName: {
      type: String,
      default: undefined
    },
    creator: {
      type: String,
      default: undefined
    }
  },
  computed: {
    me () {
      return this.$store.getters['auth/user'].user
    }
  },
  methods: {
    formatDate
  }
}
</script>

<style>

</style>
