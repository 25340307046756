const numeral = require('numeral')

numeral.register('locale', 'de', {
  delimiters: {
    thousands: '.',
    decimal: ','
  },
  abbreviations: {
    thousand: 'Tsd.',
    million: 'Mio.',
    billion: 'Bil.',
    trillion: 'Tril.'
  }
})

numeral.locale('de')

export default function (value, format) {
  if (!format || typeof format !== 'string') format = '0,0'
  const number = Number.parseFloat(value)
  if (Number.isNaN(number)) {
    return value
  }
  const formattedNumber = numeral(number).format(format)
  return formattedNumber
}
