<template>
  <modal
    v-if="!loading"
    size="md"
    title="Blacklist-Eintrag"
    :closable="true"
    @close="closeModal()"
  >
    <template #body>
      <div class="row mb-3">
        <div class="col-12 col-md-4">
          Fahrgestellnummer:
        </div>
        <div class="col-12 col-md-8">
          {{ chassi }}
        </div>
        <div class="col-12 col-md-4">
          Grund der Sperrung:
        </div>
        <div class="col-12 col-md-8">
          {{ blacklistEntry.reason.label }}
        </div>
        <div
          v-if="blacklistEntry.reasonText"
          class="col-12 col-md-4"
        >
          Zusätzliche Begründung:
        </div>
        <div
          v-if="blacklistEntry.reasonText"
          class="col-12 col-md-8"
        >
          {{ blacklistEntry.reasonText }}
        </div>
      </div>
    </template>
    <template
      #footer
    >
      <router-link
        class="btn btn-primary float-right ml-2"
        :to="{name: 'BlacklistedChassisEdit', params: {blacklistedChassisId: blacklistEntry._id}}"
      >
        Zum Blacklist-Eintrag
      </router-link>
      <button
        class="btn hover-gray-2 text-primary float-right"
        @click="closeModal()"
      >
        Schließen
      </button>
    </template>
  </modal>
</template>

<script>
import Modal from '@/components/Modal'
export default {
  $_veeValidate: {
    validator: 'new' // give me my own validator scope.
  },
  components: {
    Modal
  },
  props: {
    chassi: {
      type: String,
      required: true
    }
  },
  data: () => ({
    loading: false,
    blacklistEntry: {}
  }),
  watch: {
    chassi: {
      immediate: true,
      handler: 'fetchData'
    }
  },
  methods: {
    closeModal () {
      this.$emit('view-close')
    },
    async fetchData () {
      this.loading = true
      try {
        this.blacklistEntry = (await this.$store.dispatch('blacklisted-chassis/find', { query: { chassi: this.chassi } })).data[0]
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style>

</style>
