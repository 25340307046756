export default function (value) {
  if (value === 'ONCE') {
    return 'Einmalig'
  } else if (value === 'YEARLY') {
    return 'Jährlich'
  } else if (value === 'MONTHLY') {
    return 'Monatlich'
  } else {
    return ' '
  }
}
