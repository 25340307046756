<template>
  <div class="col-12 col-md-8">
    <AddBlacklistEntryModal
      v-if="showBlacklistAddModal"
      :chassi="chassiNumber"
      @add-close="addModalClosed($event)"
    />
    <ViewBlacklistEntryModal
      v-if="showBlacklistViewModal"
      :chassi="chassiNumber"
      @view-close="showBlacklistViewModal = false"
    />
    <div
      v-if="fetchedChassiBlacklistEntry && !chassiBlacklistEntry"
      class="d-flex align-items-center"
    >
      {{ chassiNumber }}
      <button
        class="btn hover-gray-2 px-2 py-1 my-n1 ml-1"
        @click="showBlacklistAddModal = true"
      >
        <i
          class="far fa-lock-open text-primary"
        />
      </button>
    </div>
    <div
      v-else-if="fetchedChassiBlacklistEntry && chassiBlacklistEntry"
      class="d-flex align-items-center text-danger"
    >
      {{ chassiNumber }}
      <button
        class="btn hover-gray-2 px-2 py-1 my-n1 ml-1"
        @click="showBlacklistViewModal = true"
      >
        <i
          class="far fa-info-circle text-primary"
        />
      </button>
    </div>
    <span v-else>
      {{ chassiNumber }}
    </span>
  </div>
</template>

<script>
import AddBlacklistEntryModal from '@/components/Modals/Blacklist/AddBlacklistEntryModal'
import ViewBlacklistEntryModal from '@/components/Modals/Blacklist/ViewBlacklistEntryModal'
export default {
  name: 'ChassiBlacklistInfo',
  components: {
    AddBlacklistEntryModal,
    ViewBlacklistEntryModal
  },
  props: {
    chassiNumber: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      showBlacklistAddModal: false,
      showBlacklistViewModal: false,
      chassiBlacklistEntry: null,
      fetchedChassiBlacklistEntry: false
    }
  },
  computed: {
    me () {
      return this.$store.getters['auth/user'].user
    }
  },
  watch: {
    chassiNumber: {
      immediate: true,
      handler: 'fetchChassiData'
    }
  },
  methods: {
    async fetchChassiData () {
      if (this.me.role === 10) {
        try {
          const blacklistCollection = (await this.$store.dispatch('blacklisted-chassis/find', { query: { chassi: this.chassiNumber, active: true } })).data
          if (blacklistCollection.length > 0) {
            this.chassiBlacklistEntry = blacklistCollection[0]
          }
        } catch (error) {
          console.error(error)
        } finally {
          this.fetchedChassiBlacklistEntry = true
        }
      }
    },
    async addModalClosed (success) {
      this.showBlacklistAddModal = false
      if (success) {
        this.fetchChassiData()
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
